@import "./breakpoints";

$section-spacing: 8px;

@mixin table-style() {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #111827;
    color: white;
  }
}

.table {
  @include table-style();
}

.secondary-table {
  & th {
    background-color: rgb(249, 250, 251) !important;
    color: initial !important;
  }
}

.with-natural-image-sizes {
  & img {
    width: initial !important;
    height: initial !important;
  }
}

.markdown-content {
  @include table-style();
  img {
    margin-top: $section-spacing !important;
    margin-bottom: $section-spacing !important;

    width: 100%;
    height: 100%;
    max-height: 560px;
    object-fit: contain;
  }

  h1 {
    line-height: 48px !important;
    font-size: 32px !important;
    margin-top: $section-spacing !important;
    font-weight: 700;
  }

  h2 {
    font-size: 26px !important;
    margin-top: $section-spacing !important;
    margin-bottom: $section-spacing !important;
    font-weight: 700;
    line-height: 32px !important;
  }

  h3 {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 26px !important;
    margin-top: $section-spacing !important;
  }

  h4 {
    font-size: 16px !important;
    font-weight: 700;
    line-height: 20px !important;
    margin-top: $section-spacing !important;
  }

  h5 {
    font-size: 12px !important;
    font-weight: 700;
    line-height: 20px !important;
    margin-top: $section-spacing !important;
  }

  h6 {
    font-size: 8px !important;
    font-weight: 700;
    line-height: 12px !important;
    margin-top: $section-spacing !important;
  }

  p {
    font-size: 16px;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
    font-weight: 400;
    strong {
      font-size: 16px;
    }
    em {
      font-size: 16px;
    }
    u {
      font-size: 16px;
    }
  }
  strong {
    font-weight: 700;
  }
  em {
    font-weight: 400;
    font-style: italic;
  }
  u {
    font-weight: 400;
    text-decoration: underline;
  }
  a {
    margin: 0px px;
    color: rgb(25, 118, 210);
  }

  hr {
    margin: 24px 0px;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(145, 158, 171, 0.24);
  }

  blockquote {
    border: 0 !important;

    line-height: 1.5;
    font-size: 1.5em;
    margin: 40px auto !important;
    position: relative;
    font-family: Georgia, serif;
    padding: 24px 24px 24px 64px !important;
    border-radius: 16px;
    background-color: rgb(244, 246, 248);
    color: rgb(99, 115, 129) !important;
    &::before {
      left: 16px;
      top: -8px;
      display: block;
      font-size: 3em;
      content: "“";
      position: absolute;
      color: rgb(145, 158, 171);
    }
  }

  ul {
    line-height: 1.5;
    font-size: 16px;
    font-weight: 400;
    padding-left: 40px !important;
    li {
      display: list-item;
      list-style: initial !important;
      padding: 0 !important;
    }
    & li::before {
      display: none;
    }
    & li::marker {
      font-size: 16px;
      font-weight: 400;
      line-height: 2;
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }
  }

  ol {
    line-height: 1.5;
    font-size: 16px;
    font-weight: 400;
    padding-left: 40px !important;
    li {
      display: list-item;
      list-style: initial !important;
      padding: 0 !important;
    }
    & li::before {
      display: none;
    }
    li::marker {
      font-size: 16px;
      font-weight: 400;
      line-height: 2;
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }
  }

  pre {
    font-size: 16px;
    overflow-x: auto !important;
    white-space: pre-wrap;
    padding: 16px !important;
    color: rgb(255, 255, 255) !important;
    border-radius: 8px !important;
    background-color: rgb(22, 28, 36) !important;
  }
}

.chat-user-markdown-content {
  color: white;
  p {
    font-size: 16px;
    font-weight: 400;
    strong {
      font-size: 16px;
    }
  }
  a {
    color: white;
  }
}

.security-markdown-content {
  h2,
  h3 {
    font-weight: 600;
  }
  h2 {
    font-size: 40px !important;
  }
  h3 {
    font-size: 25px !important;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    margin: 24px 0 !important;
    strong {
      font-size: 16px;
    }
  }
}

.chat-markdown-content {
  img {
    margin-top: $section-spacing !important;
    margin-bottom: $section-spacing !important;

    width: 100%;
    height: 100%;
    max-height: 560px;
    object-fit: contain;
  }
  @include table-style();
  h1 {
    line-height: 48px !important;
    font-size: 32px !important;
    margin-top: $section-spacing !important;
    font-weight: 700;
  }

  h2 {
    font-size: 26px !important;
    margin-top: $section-spacing !important;
    font-weight: 700;
    line-height: 32px !important;
  }

  h3 {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 26px !important;
    margin-top: $section-spacing !important;
  }

  h4 {
    font-size: 16px !important;
    font-weight: 700;
    line-height: 20px !important;
    margin-top: $section-spacing !important;
  }

  h5 {
    font-size: 12px !important;
    font-weight: 700;
    line-height: 20px !important;
    margin-top: $section-spacing !important;
  }

  h6 {
    font-size: 8px !important;
    font-weight: 700;
    line-height: 12px !important;
    margin-top: $section-spacing !important;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    &:not(:first-of-type) {
      margin-top: 12px;
    }
    strong {
      font-size: 16px;
    }
    em {
      font-size: 16px;
    }
    u {
      font-size: 16px;
    }
  }
  strong {
    font-weight: 700;
  }
  em {
    font-weight: 400;
    font-style: italic;
  }
  u {
    font-weight: 400;
    text-decoration: underline;
  }
  a {
    margin: 0px px;
    color: rgb(25, 118, 210);
  }

  hr {
    margin: 24px 0px;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(145, 158, 171, 0.24);
  }

  blockquote {
    border: 0 !important;

    line-height: 1.5;
    font-size: 1.5em;
    margin: 40px auto !important;
    position: relative;
    font-family: Georgia, serif;
    padding: 24px 24px 24px 64px !important;
    border-radius: 16px;
    background-color: rgb(244, 246, 248);
    color: rgb(99, 115, 129) !important;
    &::before {
      left: 16px;
      top: -8px;
      display: block;
      font-size: 3em;
      content: "“";
      position: absolute;
      color: rgb(145, 158, 171);
    }
  }

  ul {
    line-height: 1.5;
    font-size: 16px;
    font-weight: 400;
    padding-left: 40px !important;
    li {
      display: list-item;
      padding: 0 !important;
    }
    & li::before {
      display: none;
    }
    & li::marker {
      font-size: 16px;
      font-weight: 400;
      line-height: 2;
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }
  }

  ol {
    line-height: 1.5;
    font-size: 16px;
    font-weight: 400;
    padding-left: 40px !important;
    li {
      display: list-item;
      padding: 0 !important;
    }
    & li::before {
      display: none;
    }
    li::marker {
      font-size: 16px;
      font-weight: 400;
      line-height: 2;
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }
  }

  pre {
    font-size: 16px;
    white-space: pre-wrap;
    overflow-x: auto !important;
    padding: 16px !important;
    color: rgb(255, 255, 255) !important;
    border-radius: 8px !important;
    background-color: rgb(22, 28, 36) !important;
  }
}

.block-quote {
  border: 0 !important;
  line-height: 1.5;
  font-size: 1.5em;
  position: relative;
  font-family: Georgia, serif;
  padding: 24px 24px 24px 64px !important;
  border-radius: 16px;
  background-color: transparent;
  &::before {
    left: 16px;
    top: -8px;
    display: block;
    font-size: 3em;
    content: "“";
    position: absolute;
    color: rgb(145, 158, 171);
  }
}

.with-html-black-bg {
  & a {
    color: white;
  }
}
.with-html-white-bg {
  & a {
    color: black;
  }
}

.ql-toolbar-button {
  border-radius: 4px;
  margin: 0 5px;
  &:hover {
    background-color: #ebeff4 !important;
  }
  & svg {
    fill: #3a506b !important;
  }
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.letter-loading {
  float: left;
  font-size: 14px;
  color: #777;
  display: flex;
  color: #ce2eda;
  font-weight: 500;
  line-height: 19px;
}

.letter {
  animation-name: loadingF;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.letter-dot-loading {
  float: left;
  font-size: 14px;
  color: #777;
  display: flex;
  font-weight: 500;
  line-height: 19px;
}
.letter-dot {
  opacity: 0;
}
.letter-dot-1 {
  animation-delay: 0.2s;

  animation: dot-one 0.8s infinite linear;
}
.letter-dot-2 {
  animation-delay: 0.2s;

  animation: dot-two 0.8s infinite linear;
}
.letter-dot-3 {
  animation-delay: 0.6s;

  animation: dot-three 0.8s infinite linear;
}

@keyframes dot-one {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes dot-two {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes dot-three {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.1;
  }
}

.l-1 {
  animation-delay: 0.48s;
}
.l-2 {
  animation-delay: 0.6s;
}
.l-3 {
  animation-delay: 0.72s;
}
.l-4 {
  animation-delay: 0.84s;
}
.l-5 {
  animation-delay: 0.96s;
}
.l-6 {
  animation-delay: 1.08s;
}
.l-7 {
  animation-delay: 1.2s;
}
.l-8 {
  animation-delay: 1.32s;
}
.l-9 {
  animation-delay: 1.44s;
}
.l-10 {
  animation-delay: 1.56s;
}

.html-link {
  color: #911ba4;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    text-decoration: underline;
  }
}

$section-spacing: 8px;

.editor-style {
  :global(div.ql-toolbar) {
    width: 100%;
    position: absolute;
    z-index: 1;
  }

  :global(.ql-container) {
    max-width: 800px;
    margin: auto;
  }
  .ql-disabled {
    & * {
      cursor: wait !important;
    }
    cursor: wait !important;
  }
  :global(.ql-tooltip.ql-editing) {
    left: 50% !important;
    transform: translateX(-50%);
  }
  :global(.ql-clipboard) {
    position: fixed !important;
  }

  img {
    margin-top: $section-spacing !important;
    margin-bottom: $section-spacing !important;

    height: 100%;
    object-fit: contain;
  }

  h1 {
    line-height: 48px !important;
    font-size: 32px !important;
    margin-top: $section-spacing !important;
    font-weight: 700;
  }

  h2 {
    font-size: 24px !important;
    margin-top: $section-spacing !important;
    font-weight: 700;
    line-height: 32px !important;
  }

  h3 {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 26px !important;
    margin-top: $section-spacing !important;
  }

  h4 {
    font-size: 16px !important;
    font-weight: 700;
    line-height: 20px !important;
    margin-top: $section-spacing !important;
  }

  h5 {
    font-size: 12px !important;
    font-weight: 700;
    line-height: 20px !important;
    margin-top: $section-spacing !important;
  }

  h6 {
    font-size: 8px !important;
    font-weight: 700;
    line-height: 12px !important;
    margin-top: $section-spacing !important;
  }

  p:not(.file-upload-button-label) {
    font-size: 16px;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
    font-weight: 400;
    strong {
      font-size: 16px;
    }
    em {
      font-size: 16px;
    }
    u {
      font-size: 16px;
    }
  }
  strong {
    font-weight: 700;
  }
  em {
    font-weight: 400;
    font-style: italic;
  }
  u {
    font-weight: 400;
    text-decoration: underline;
  }
  a {
    margin: 0px px;
    color: rgb(25, 118, 210);
  }

  hr {
    margin: 24px 0px;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(145, 158, 171, 0.24);
  }

  blockquote {
    border: 0 !important;

    line-height: 1.5;
    font-size: 1.5em;
    margin: 40px auto !important;
    position: relative;
    font-family: Georgia, serif;
    padding: 24px 24px 24px 64px !important;
    border-radius: 16px;
    background-color: rgb(244, 246, 248);
    color: rgb(99, 115, 129) !important;
    &::before {
      left: 16px;
      top: -8px;
      display: block;
      font-size: 3em;
      content: "“";
      position: absolute;
      color: rgb(145, 158, 171);
    }
  }

  ul {
    line-height: 1.5;
    font-size: 16px;
    font-weight: 400;
    padding-left: 40px !important;
    li {
      display: list-item;
      list-style: initial !important;
      padding: 0 !important;
    }
    & li::before {
      display: none;
    }
    & li::marker {
      font-size: 16px;
      font-weight: 400;
      line-height: 2;
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }
  }

  ol {
    line-height: 1.5;
    font-size: 16px;
    font-weight: 400;
    padding-left: 40px !important;
    li {
      display: list-item;
      list-style: initial !important;
      padding: 0 !important;
    }
    & li::before {
      display: none;
    }
    li::marker {
      font-size: 16px;
      font-weight: 400;
      line-height: 2;
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }
  }

  pre {
    font-size: 16px;
    overflow-x: auto !important;
    padding: 16px !important;
    color: rgb(255, 255, 255) !important;
    border-radius: 8px !important;
    background-color: rgb(22, 28, 36) !important;
  }
  details {
    margin: 1rem auto;
    padding: 0 1rem;
    width: 35em;
    max-width: calc(100% - 2rem);
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    color: #263238;
    transition: background-color 0.15s;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    > :last-child {
      margin-bottom: 1rem;
    }

    &::before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-radius: inherit;
      opacity: 0.15;
      box-shadow: 0 0.25em 0.5em #263238;
      pointer-events: none;
      transition: opacity 0.2s;
      z-index: -1;
    }

    &[open] {
      background-color: #fff;

      &::before {
        opacity: 0.6;
      }
    }
  }

  .faq-answer {
    margin: 0 0 1em;
    line-height: 1.5;
  }
  summary {
    padding: 1rem 2em 1rem 0;
    display: block;
    position: relative;
    font-size: 1.33em;
    font-weight: bold;
    cursor: pointer;

    &::before,
    &::after {
      width: 0.75em;
      height: 2px;
      position: absolute;
      top: 50%;
      right: 0;
      content: "";
      background-color: currentColor;
      text-align: right;
      transform: translateY(-50%);
      transition: transform 0.2s ease-in-out;
    }

    &::after {
      transform: translateY(-50%) rotate(90deg);

      [open] & {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &::-webkit-details-marker {
      display: none;
    }
  }
}

.testmonial-dot-list {
  bottom: 20px !important;
  & button {
    border-width: 0 !important;
  }
}
.ql-clipboard {
  position: fixed !important;
}
.file-icon {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}
.file-upload-button-label {
  margin: 6px 0;
  font-family: __Inter_0ec1f4, __Inter_Fallback_0ec1f4, sans-serif;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px !important;
  font-weight: 400;
}
.file-upload-tooltip {
  padding: 24px !important;
  border-radius: 10px !important;
  border: 0 !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  & input {
    border: 1px solid rgb(229, 231, 235) !important;
    border-radius: 4px !important;
    &:focus {
      border: 1px solid rgb(229, 231, 235) !important;
      border-radius: 8px !important;
    }
  }
  & .ql-action {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 12px !important;
  }
  &::before {
    content: "URL:" !important;
  }
}
.file-upload-button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    opacity: 0.8;
  }
}

.or-root {
  margin: 12px !important;
  flex-shrink: 0;
  display: flex;
  white-space: nowrap;
  text-align: center;
  border: 0px;
  &::before {
    position: relative;
    width: 100%;
    border-top: thin solid rgba(0, 0, 0, 0.12);
    top: 50%;
    content: "";
    transform: translateY(50%);
  }
  &::after {
    position: relative;
    width: 100%;
    border-top: thin solid rgba(0, 0, 0, 0.12);
    top: 50%;
    content: "";
    transform: translateY(50%);
  }
}

.or-wrapper {
  display: inline-block;
  padding-left: calc(9.6px);
  padding-right: calc(9.6px);
}
.or-chip {
  max-width: 100%;
  font-size: 0.8125rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
}
.or-label {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
}

.blog-markdown {
  img {
    max-height: 400px;
    border-radius: 22px;
    object-fit: cover;
  }
}

.blog-image {
  width: 100%;
  height: 100%;
  border-radius: 22px;
  object-fit: cover;
  max-height: 800px;
}

.front-page-bg {
  position: relative;
  background: linear-gradient(188deg, #1b4bf4 0%, #2a6bb9 52.1%, #1ac6d1 100%);
  &::after {
    display: none;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-image: url(../../public/bg.png);
    background-position: right bottom, left top;
    background-repeat: no-repeat, repeat;
  }
}
