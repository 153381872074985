@mixin with-hover-underline-effect($element) {
  #{$element} {
    position: relative;
    display: inline;
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(currentColor),
      to(currentColor)
    );
    background-image: -webkit-linear-gradient(
      left,
      currentColor 0%,
      currentColor 100%
    );
    background-image: -o-linear-gradient(
      left,
      currentColor 0%,
      currentColor 100%
    );
    background-image: linear-gradient(
      to right,
      currentColor 0%,
      currentColor 100%
    );
    background-size: 0px 2px;
    background-position: 0px 95%;
    -webkit-transition: background-size 0.25s
      cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    -o-transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86)
      0s;
    transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    padding: 0.1% 0px;
    background-repeat: no-repeat;
  }
  &:hover #{$element} {
    color: inherit;
    background-size: 100% 2px !important;
  }
}

.with-hover-underline-effect-p {
  @include with-hover-underline-effect(p);
}

.with-hover-underline-effect-a {
  @include with-hover-underline-effect(a);
}

.with-hover-underline-effect-h4 {
  @include with-hover-underline-effect(h4);
}

.image-zoom-wrapper::after {
  opacity: 0;
  transition: opacity 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  display: block;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 99998;
  pointer-events: none;
}
.image-zoom-wrapper.image-zoom-wrapper-zoomed::after {
  opacity: 0.7;
  cursor: zoom-out;
  pointer-events: all;
}
.image-zoom {
  transition: transform 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: zoom-in;
}
.image-zoom-zoomed {
  position: relative;
  z-index: 99999;
  cursor: zoom-out;
}

body {
  scrollbar-color: grey transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    &-track {
      background-color: rgb(255 255 255 / 10%);
      border-radius: 20px;
    }
    &-thumb {
      background-color: grey;
      background-image: linear-gradient(45deg, #00aeff, #a68eff);
      border-radius: 20px;
    }
  }
}

.fade-in.react-multi-carousel-item--active {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
